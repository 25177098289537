modalMethods = angular.module('webApp');

modalMethods.factory('modalMethods', ['$http', function ($http) {

   return {
       show : function(id){
           $("#" + id).modal('show')
       },
       close : function(id){

           $("#" + id).modal('hide');

       }
   };

}]);
