webApp = angular.module('webApp');

var url_api = "/api/";

webApp.factory('baseHttp', ['$http', function($http){

    return {
        getBannerServicio : function getBannerServicio(parametros) {
            return $http.get(url_api + 'servicios/categorias/banner' + parametros);
        },
        getPromocionesServicio : function getPromocionesServicio() {
            return $http.get(url_api + 'servicios/promociones/banner');
        },
        getBuscarInfoEmpresa : function getBuscarInfoEmpresa() {
            return $http.get(url_api + 'empresa/info');
        },
        getListarServicios : function getListarServicios() {
            return $http.get(url_api + 'servicios/categorias/completa');
        },
        postEnviarSuscripcion: function postEnviarSuscripcion(correo) {
            return $http.post(url_api + 'contacto/suscripcion',{
                'correo' : correo
            });
        },
        postEnviarMensaje: function postEnviarMensaje(data) {
            return $http.post(url_api + 'contacto/mensaje',{
                'data' : data
            });
        },
        postEnviarFormularioFranquiciante: function postEnviarFormularioFranquiciante(data) {
            return $http.post(url_api + 'contacto/franquiciante', data, {
                headers: {'Content-Type': undefined },
                transformRequest: angular.identity
            });
        },
        postEnviarFormularioTrabajaConNosotros: function postEnviarFormularioTrabajaConNosotros(data) {
            return $http.post(url_api + 'contacto/trabaja-con-nosotros', data, {
                headers: {'Content-Type': undefined },
                transformRequest: angular.identity
            });
        }
    }

}]);