scrollFocus = angular.module('webApp');

scrollFocus.factory('scrollFocus', ['$http', function ($http) {

   return {
       animate : function(id){

           $('html,body').animate({scrollTop: $("#" + id).offset().top},3000);

       }
   };

}]);
