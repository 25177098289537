webApp.controller('baseController', ['$scope', '$window', '$sce', 'baseHttp', 'scrollFocus', 'modalMethods', 'SweetAlert',
    function ($scope, $window, $sce, baseHttp, scrollFocus, modalMethods, SweetAlert) {

        var baseVm = this;
        baseVm.contadorIntentosCargarEmpresaBanner = 0;
        baseVm.contadorIntentosCargarPromocionesBanner = 0;
        baseVm.contadorIntentosCargarEmpresaInfo = 0;
        baseVm.contadorIntentosCargarListarServicios = 0;
        baseVm.menuActivo = false;
        baseVm.menuServicioActivo = false;
        baseVm.menuActivoHover = false;
        baseVm.enviandoSuscripcion = false;
        baseVm.panelContactoShow = false;
        baseVm.menuActivoStyle = {};
        baseVm.menuServicioActivoStyle = {};
        baseVm.banner = {};
        baseVm.promociones = {};
        baseVm.promocionesClass = {};
        baseVm.empresaInfo = {};
        baseVm.listaServicios = [];
        baseVm.bannerExcluir = "";
        baseVm.correoSuscripcion = "";
        baseVm.inputEmailNewsletter="EMAIL";

        baseVm.trustAsHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

        baseVm.menu = function () {
            baseVm.menuActivo = !baseVm.menuActivo;
            if (baseVm.menuActivo) {
                baseVm.menuActivoStyle = {
                    'display': 'block'
                };
            } else {
                baseVm.menuActivoStyle = {
                    'display': 'none'
                };
            }
        };

        baseVm.menuServicio = function () {

            baseVm.menuServicioActivo = !baseVm.menuServicioActivo;
            if (baseVm.menuServicioActivo) {
                baseVm.menuServicioActivoStyle = {
                    'display': 'block'
                };
            } else {
                baseVm.menuServicioActivoStyle = {
                    'display': 'none'
                };
            }
        };

        baseVm.menuOpcion = function (idSeccion) {
            scrollFocus.animate(idSeccion);
            baseVm.menu();

        };

        baseVm.mscrollAnimate = function (idSeccion) {
            scrollFocus.animate(idSeccion);

        };

        baseVm.listarServicios = function () {

            baseHttp.getListarServicios().then(function (resultado) {

                baseVm.listaServicios = resultado.data.categorias;

            }).catch(function (resultado) {

                if (baseVm.contadorIntentosCargarListarServicios < 2) {
                    baseVm.contadorIntentosCargarListarServicios++;
                    baseVm.listarServicios();
                }

            });
        };

        baseVm.bannerServicio = function () {

            var parametros = "";

            if (baseVm.bannerExcluir) {
                parametros = '?excluir=' + baseVm.bannerExcluir;
            }

            baseHttp.getBannerServicio(parametros).then(function (resultado) {

                baseVm.banner = resultado.data.banner;

                baseVm.bannerClass = {
                    'background-image': 'url(' + resultado.data.banner.imagen + ')'
                };

            }).catch(function (resultado) {

                if (baseVm.contadorIntentosCargarEmpresaBanner < 2) {
                    baseVm.contadorIntentosCargarEmpresaBanner++;
                    baseVm.bannerServicio();
                }

            });

        };

        baseVm.procionesServicios = function () {

            baseHttp.getPromocionesServicio().then(function (resultado) {

                baseVm.promociones = resultado.data.promociones;

                baseVm.promocionesClass = {
                    'background-image': 'url(' + resultado.data.promociones.imagen + ')'
                };

            }).catch(function (resultado) {

                if (baseVm.contadorIntentosCargarPromocionesBanner < 2) {
                    baseVm.contadorIntentosCargarPromocionesBanner++;
                    baseVm.procionesServicios();
                }

            });


        };

        baseVm.buscarInfoEmpresa = function () {

            baseHttp.getBuscarInfoEmpresa().then(function (resultado) {

                baseVm.empresaInfo = resultado.data.empresa;


            }).catch(function (resultado) {
                if (baseVm.contadorIntentosCargarEmpresaInfo < 2) {
                    baseVm.contadorIntentosCargarEmpresaInfo++;
                    baseVm.buscarInfoEmpresa();
                }
            });


        };

        baseVm.enviarSuscripcion = function () {

            baseVm.enviandoSuscripcion = false;

            if (baseVm.correoSuscripcion) {

                baseVm.enviandoSuscripcion = true;

                baseHttp.postEnviarSuscripcion(baseVm.correoSuscripcion).then(function (resultado) {
                    baseVm.correoSuscripcion = "";
                    baseVm.enviandoSuscripcion = false;
                    SweetAlert.swal("Buen trabajo", "Se ha suscrito a nuestro canal informativo.", "success");

                }).catch(function (resultado) {
                    baseVm.enviandoSuscripcion = false;
                    SweetAlert.swal("Error", 'Lo sentimos en este momento no podemos procesar su registro ' +
                        'a nuestro canal informativo.', "error");
                });

            }


        };

        baseVm.enviarMensaje = function () {

            baseVm.errorNombre = false;
            baseVm.errorCorreo = false;
            baseVm.errorTelefono = false;
            baseVm.errorMensaje = false;
            baseVm.iconoEnviando = false;
            baseVm.errorEnvio = false;

            if (baseVm.nombre) {
                if (baseVm.correo) {
                    if (baseVm.telefono) {
                        if (baseVm.mensaje) {
                            baseVm.iconoEnviando = true;
                            var data = {

                                'nombre': baseVm.nombre,
                                'correo': baseVm.correo,
                                'telefono': baseVm.telefono,
                                'mensaje': baseVm.mensaje

                            };

                            baseHttp.postEnviarMensaje(data).then(function (resultado) {
                                baseVm.nombre = "";
                                baseVm.correo = "";
                                baseVm.telefono = "";
                                baseVm.mensaje = "";
                                baseVm.iconoEnviando = false;
                                SweetAlert.swal("Correo enviado", "Te estaremos contactando.", "success");

                            }).catch(function (resultado) {
                                baseVm.iconoEnviando = false;
                                baseVm.errorEnvio = true;
                                SweetAlert.swal("Error", 'Lo sentimos en este momento no podemos procesar su correo.', "error");
                            });

                        } else {
                            baseVm.errorMensaje = true;
                        }
                    } else {
                        baseVm.errorTelefono = true;
                    }
                } else {
                    baseVm.errorCorreo = true;
                }
            } else {
                baseVm.errorNombre = true;
            }


        };

        baseVm.abrirModalFranquiciante = function (id) {
            baseVm.nombreFranquiciante = "";
            baseVm.apellidosFranquiciante = "";
            baseVm.emailFranquiciante = "";
            baseVm.telefonoFranquiciante = "";
            baseVm.profecionFranquiciante = "";
            baseVm.edadFranquiciante = "";
            baseVm.estadoCivilFranquiciante = "";
            baseVm.poblacionRecidenciaFranquiciante = "";
            baseVm.poblacionInteresFranquiciante = "";
            baseVm.mensajeFranquiciante = "";
            baseVm.iconoEnviandoDataFranquiciante = false;
            modalMethods.show(id);
        };

        baseVm.abrirModalTrabajaConNosotros = function (id) {
            baseVm.nombreTrabajaConNosotros = "";
            baseVm.apellidosTrabajaConNosotros = "";
            baseVm.emailTrabajaConNosotros = "";
            baseVm.telefonoTrabajaConNosotros = "";
            baseVm.fileTrabajaConNosotros = null;
            baseVm.iconoEnviandoDataTrabajaConNosotros = false;
            angular.element(document.querySelector("#fileTrabajaConNosotrosInput")).val(null);
            modalMethods.show(id);
        };

        baseVm.enviarDatosFranquiciante = function () {

            baseVm.nombreFranquicianteError = false;
            baseVm.apellidosFranquicianteError = false;
            baseVm.emailFranquicianteError = false;
            baseVm.telefonoFranquicianteError = false;
            baseVm.profecionFranquicianteError = false;
            baseVm.edadFranquicianteError = false;
            baseVm.estadoCivilFranquicianteError = false;
            baseVm.poblacionRecidenciaFranquicianteError = false;
            baseVm.poblacionInteresFranquicianteError = false;
            baseVm.iconoEnviandoDataFranquiciante = false;

            if (baseVm.nombreFranquiciante) {
                if (baseVm.apellidosFranquiciante) {
                    if (baseVm.emailFranquiciante) {
                        if (baseVm.telefonoFranquiciante) {
                            if (baseVm.profecionFranquiciante) {
                                if (baseVm.edadFranquiciante) {
                                    if (baseVm.estadoCivilFranquiciante) {
                                        if (baseVm.poblacionRecidenciaFranquiciante) {
                                            if (baseVm.poblacionInteresFranquiciante) {
                                                var data = new FormData();
                                                data.append('nombreFranquiciante', baseVm.nombreFranquiciante);
                                                data.append('apellidosFranquiciante', baseVm.apellidosFranquiciante);
                                                data.append('emailFranquiciante', baseVm.emailFranquiciante);
                                                data.append('telefonoFranquiciante', baseVm.telefonoFranquiciante);
                                                data.append('profecionFranquiciante', baseVm.profecionFranquiciante);
                                                data.append('edadFranquiciante', baseVm.edadFranquiciante);
                                                data.append('estadoCivilFranquiciante', baseVm.estadoCivilFranquiciante);
                                                data.append('poblacionRecidenciaFranquiciante', baseVm.poblacionRecidenciaFranquiciante);
                                                data.append('poblacionInteresFranquiciante', baseVm.poblacionInteresFranquiciante);
                                                if (baseVm.mensajeFranquiciante) {
                                                    data.append('mensajeFranquiciante', baseVm.mensajeFranquiciante)
                                                }

                                                baseVm.iconoEnviandoDataFranquiciante = true;

                                                baseHttp.postEnviarFormularioFranquiciante(data).then(function (resultado) {

                                                    baseVm.nombreFranquiciante = "";
                                                    baseVm.apellidosFranquiciante = "";
                                                    baseVm.emailFranquiciante = "";
                                                    baseVm.telefonoFranquiciante = "";
                                                    baseVm.profecionFranquiciante = "";
                                                    baseVm.edadFranquiciante = "";
                                                    baseVm.estadoCivilFranquiciante = "";
                                                    baseVm.poblacionRecidenciaFranquiciante = "";
                                                    baseVm.poblacionInteresFranquiciante = "";
                                                    baseVm.mensajeFranquiciante = "";
                                                    baseVm.iconoEnviandoDataFranquiciante = false;
                                                    modalMethods.close('modalFranquicia');
                                                    SweetAlert.swal("En hora buena", "Se ha recibido con dicha tus datos te estaremos contactando pronto.", "success");

                                                }).catch(function (resultado) {
                                                    baseVm.iconoEnviandoDataFranquiciante = false;
                                                    modalMethods.close('modalFranquicia');
                                                    SweetAlert.swal("Error", 'Lo sentimos en este momento no podemos procesar sus datos.', "error");
                                                });

                                            } else {
                                                baseVm.poblacionInteresFranquicianteError = true;
                                            }
                                        } else {
                                            baseVm.poblacionRecidenciaFranquicianteError = true;
                                        }
                                    } else {
                                        baseVm.estadoCivilFranquicianteError = true;
                                    }
                                } else {
                                    baseVm.edadFranquicianteError = true;
                                }
                            } else {
                                baseVm.profecionFranquicianteError = true;
                            }
                        } else {
                            baseVm.telefonoFranquicianteError = true;
                        }
                    } else {
                        baseVm.emailFranquicianteError = true;
                    }
                } else {
                    baseVm.apellidosFranquicianteError = true;
                }
            } else {
                baseVm.nombreFranquicianteError = true;
            }


        };

        baseVm.enviarDatosTrabajaConNosotros = function () {

            baseVm.nombreTrabajaConNosotrosError = false;
            baseVm.apellidosTrabajaConNosotrosError = false;
            baseVm.emailTrabajaConNosotrosError = false;
            baseVm.telefonoTrabajaConNosotrosError = false;
            baseVm.fileTrabajaConNosotrosError = false;
            baseVm.fileFormatoTrabajaConNosotrosError = false;
            baseVm.filesizeTrabajaConNosotrosError = false;
            baseVm.iconoEnviandoDataTrabajaConNosotros = false;
            var data = new FormData();
            var bytes = 0;
            var Kilobyte = 0;
            var megabytes = 0;


            if (baseVm.nombreTrabajaConNosotros) {
                if (baseVm.apellidosTrabajaConNosotros) {
                    if (baseVm.emailTrabajaConNosotros) {
                        if (baseVm.telefonoTrabajaConNosotros) {
                            if (baseVm.fileTrabajaConNosotros) {
                                if (baseVm.fileTrabajaConNosotros.type == "application/pdf") {
                                    bytes = baseVm.fileTrabajaConNosotros.size;
                                    Kilobyte = parseInt(bytes / 1024);
                                    megabytes = parseInt(Kilobyte / 1024);
                                    if (megabytes <= 2) {

                                        data.append('nombreTrabajaConNosotros', baseVm.nombreTrabajaConNosotros);
                                        data.append('apellidosTrabajaConNosotros', baseVm.apellidosTrabajaConNosotros);
                                        data.append('emailTrabajaConNosotros', baseVm.emailTrabajaConNosotros);
                                        data.append('telefonoTrabajaConNosotros', baseVm.telefonoTrabajaConNosotros);
                                        data.append('fileTrabajaConNosotros', baseVm.fileTrabajaConNosotros);

                                        baseVm.iconoEnviandoDataTrabajaConNosotros = true;

                                        baseHttp.postEnviarFormularioTrabajaConNosotros(data).then(function (resultado) {

                                            baseVm.nombreTrabajaConNosotros = "";
                                            baseVm.apellidosTrabajaConNosotros = "";
                                            baseVm.emailTrabajaConNosotros = "";
                                            baseVm.telefonoTrabajaConNosotros = "";
                                            baseVm.fileTrabajaConNosotros = "";
                                            baseVm.iconoEnviandoDataTrabajaConNosotros = false;
                                            modalMethods.close('modalTrabajo');
                                            SweetAlert.swal("En hora buena", "Se ha recibido con dicha tus datos te estaremos contactando pronto.", "success");

                                        }).catch(function (resultado) {
                                            baseVm.iconoEnviandoDataTrabajaConNosotros = false;
                                            modalMethods.close('modalTrabajo');
                                            SweetAlert.swal("Error", 'Lo sentimos en este momento no podemos procesar sus datos.', "error");
                                        });


                                    } else {
                                        baseVm.filesizeTrabajaConNosotrosError = true;
                                    }
                                } else {
                                    baseVm.fileFormatoTrabajaConNosotrosError = true;
                                }

                            } else {
                                baseVm.fileTrabajaConNosotrosError = true;
                            }
                        } else {
                            baseVm.telefonoTrabajaConNosotrosError = true;
                        }
                    } else {
                        baseVm.emailTrabajaConNosotrosError = true;
                    }
                } else {
                    baseVm.apellidosTrabajaConNosotrosError = true;
                }
            } else {
                baseVm.nombreTrabajaConNosotrosError = true;
            }

        };

        baseVm.hojaDeVida = function (file) {

            var reader = new FileReader();

            baseVm.fileTrabajaConNosotros = file[0];

        };

        baseVm.initDataBanner = function (bannerId) {

            if (bannerId) {
                baseVm.bannerExcluir = bannerId;
            }
            baseVm.bannerServicio();

            console.log(baseVm.bannerExcluir);
        };

        baseVm.procionesServicios();
        baseVm.buscarInfoEmpresa();
        baseVm.listarServicios();


    }]);